import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/account/logout"),
  },

  {
    path: "/reset-password/:token",
    name: "Reset Password",
    component: () => import("../views/account/reset-password.vue"),
    meta: {
      title: "Reset Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        // Continue to the login page
        next();
      },
    },
  },

  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/pages/starter.vue"),
  },

  // Menus
  {
    path: "/settings/menus",
    name: "Menus",
    meta: { title: "Menu", authRequired: true },
    component: () => import("../views/maintenance/menus/index"),
  },
  {
    path: "/settings/menus/form",
    name: "AddMenu",
    meta: { title: "Add Menu", authRequired: true },
    component: () => import("../views/maintenance/menus/form"),
  },
  {
    path: "/settings/menus/form/:id",
    name: "EditMenu",
    meta: { title: "Edit Menu", authRequired: true },
    component: () => import("../views/maintenance/menus/form"),
  },

  // Permissions
  {
    path: "/settings/permissions",
    name: "Permissions",
    meta: { title: "Permission", authRequired: true },
    component: () => import("../views/user-management/permissions/index"),
  },
  {
    path: "/settings/permissions/form",
    name: "AddPermission",
    meta: { title: "Add Permission", authRequired: true },
    component: () => import("../views/user-management/permissions/form"),
  },
  {
    path: "/settings/permissions/form/:id",
    name: "EditPermission",
    meta: { title: "Edit Permission", authRequired: true },
    component: () => import("../views/user-management/permissions/form"),
  },

  // Roles
  {
    path: "/settings/roles",
    name: "Roles",
    meta: { title: "Role", authRequired: true },
    component: () => import("../views/user-management/roles/index"),
  },
  {
    path: "/settings/roles/:id",
    name: "ViewRole",
    meta: { title: "View Role", authRequired: true },
    component: () => import("../views/user-management/roles/info"),
  },
  {
    path: "/settings/roles/form",
    name: "AddRole",
    meta: { title: "Add Role", authRequired: true },
    component: () => import("../views/user-management/roles/form"),
  },
  {
    path: "/settings/roles/form/:id",
    name: "EditRole",
    meta: { title: "Edit Role", authRequired: true },
    component: () => import("../views/user-management/roles/form"),
  },

  // Modules
  {
    path: "/settings/modules",
    name: "Modules",
    meta: { title: "Module", authRequired: true },
    component: () => import("../views/user-management/modules/index"),
  },
  {
    path: "/settings/modules/form",
    name: "AddModule",
    meta: { title: "Add Module", authRequired: true },
    component: () => import("../views/user-management/modules/form"),
  },
  {
    path: "/settings/modules/form/:id",
    name: "EditModule",
    meta: { title: "Edit Module", authRequired: true },
    component: () => import("../views/user-management/modules/form"),
  },

  // Users
  {
    path: "/settings/users",
    name: "Users",
    meta: { title: "User", authRequired: true },
    component: () => import("../views/user-management/users/index"),
  },
  {
    path: "/settings/users/:id",
    name: "UserProfile",
    meta: { title: "User Profile", authRequired: true },
    component: () => import("../views/user-management/users/profile"),
  },
  {
    path: "/users/profile/:id",
    name: "UserProfile2",
    meta: { title: "User Profile", authRequired: true },
    component: () => import("../views/user-management/users/user-profile"),
  },
  {
    path: "/settings/users/form",
    name: "AddUser",
    meta: { title: "Add User", authRequired: true },
    component: () => import("../views/user-management/users/form"),
  },
  {
    path: "/settings/users/form/:id",
    name: "EditUser",
    meta: { title: "Edit User", authRequired: true },
    component: () => import("../views/user-management/users/form"),
  },

  {
    path: "/user/profile",
    name: "UserProfileView",
    meta: { title: "User", authRequired: true },
    component: () => import("../views/user-management/users/user-profile"),
  },

  // Operating Unit
  {
    path: "/maintenance/ou/index",
    name: "OperatingUnit",
    meta: { title: "Operating Unit", authRequired: true },
    component: () => import("../views/maintenance/ou/index"),
  },
];